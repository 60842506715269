.mzad-footer{
    .head-office{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        .ho-icon{
            display: flex; 
            justify-content: flex-start
        }
        .ho-title{
            font-weight: bold;
            width: 150px;
        }
        .head-office-map{
            img{
                width: 80%
            }
        }
    }
    .footer-mid-section{
        .address-whoweare{
            display: flex; 
            justify-content: space-around;
            .address{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
                .addr-title{
                    font-weight: bold
                }
            }

            .addr-text{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
            }
            .who-we-are{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
                .wwr-title{
                    font-weight: bold
                }
                .wwr-content{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                }
            }
        }
        .contact{
            margin-top: 2rem; padding-left: 10%; display: flex; gap: 2rem
        }
    }
    .footer-right-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        .frs-cont{
            display: flex; gap: 1rem
        }
    }
}