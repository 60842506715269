body {
    &.ar{
        direction: rtl;

        .timer-label-container{
            flex-direction: row-reverse;
        }
        .login-btn{
            flex-direction: row-reverse;
        }
        .wallet-btn{
            flex-direction: row-reverse;
        }
        #auction-grouped{
            .pagination{
                text-align: left;
            }
            .left-section {
                margin-right: 10px !important;
            }
            .left-image {
                margin-right: 0px !important;
            }
        }

        .item1-links{
            a{
                &.active{
                    background-color: #eaf2f5;
                    border-left: none;
                    border-right: 10px solid #76acc1;
                }
            }
        }

        .auc-card-container{
            .content-div {
                .outer-div{
                    direction: rtl;
                }
                .container-dates {
                    .col-cont-details {
                        .col-cal-cont {
                            p {
                                margin: 0;
                                transform: translate(5px, 0px);
                            }
                        }
                    }
                }
            }
        }
        .btn-close {
            left: 35px !important;
            right: inherit !important;
        }
        .footer-message {
            right: inherit;
            left: 0rem;
            z-index: 99;
        }
        .page-container{
            .password-eye{
                right: inherit;
                left: 1rem;
            }
        }

        #page-container-otp-login{
            .content{
                .main-container{
                    .container-header{
                        margin-right: 40px;
                    }
                } 
            } 
        }

        .flag-dd{
            .btn-country{
                &::after{
                    margin-left: inherit;
                    margin-right: 2em;
                }
                img{
                    border-radius: 0px 11px 11px 0px;
                }
            }
        }
        thead:first-child {
            th:first-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 20px;
            }
            th:last-child {
                border-top-right-radius: 0px;
                border-top-left-radius: 20px;
            }
        }
        .border-span{
            &.days{
                left: inherit;
                right: 5.3rem;
            }
            &.hours{
                left: inherit;
                right: 7rem;
            }
            &.minutes{
                left: inherit;
                right: 9rem;
            }
            &.seconds{
                left: inherit;
                right: 10.7rem;
            }
        }

        // @media (min-width:768px) {
            .timeline-steps .timeline-step:not(:last-child):after {
                content: "";
                display: block;
                border-top: .2rem solid #cdcdcd;
                width: 3.46rem;
                position: absolute;
                left: inherit;
                right: 7.5rem;
                top: .3125rem;
            }
            .timeline-steps .timeline-step:not(:first-child):before {
                content: "";
                display: block;
                border-top: .2rem solid #cdcdcd;
                width: 3.8125rem;
                position: absolute;
                right: inherit;
                left: 6.1rem;
                top: .3125rem;
            }
        //}

        @media (max-width:768px) {
            .mob-field{
                padding-right: 5rem !important;
                padding-left: 0rem !important;
              }
            .cloud-upload-btn-wrapper {
                .uploader-format-viewer{
                  left: 5px;
                }
            }
            .track-wrapper{
                .timeline-steps{
                  justify-content: right;
                }
            }
        }
    }
}