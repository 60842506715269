@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.navy-blue-div {
    background-color: #1A1E42;
    color: white;
    padding: 20px;
    text-align: center;
}

.navy-blue-div-II {
    height: 49px;
    background-color: #1A1E42;
}
.sky-blue-div-II{
    background-color: #D2F2FF;
    .auction-tab-item{
        background-color: #D2F2FF !important;
        &:hover {
            background-color: #1A1E42 !important;
            border-radius: 50px;
            a{
                color: #FFF !important;
            }
        }
        &.is-active {
            background-color: #1A1E42 !important;
            border-radius: 50px;
            a{
                color: #FFF !important;
            }
        }
        a{
            color: #000000;
        }
    }
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.image-container {
    position: relative;
    padding: 4px;
}

.center-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* --------till___nav---header */
.auction-button {
    background-color: #FFE1C8;
    border-radius: 50px;
    border: none;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 50px;
    padding: 1rem;
    span{
        font-size: 28px !important;
    }
}

.footer-message{
    position: fixed;
    bottom: 5%;
    right: 0rem;
    z-index: 99;
    &.auction-button {
        border: 4px solid #FA8500;
        border-radius: 20px;
        background-color: #FFFFFF;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .image-container{
            margin-right: 10px;
        }
        .msg-label{
            font-size: 17px !important; 
            font-weight: 500; 
            color: #FA8500;
        }
    }
}

.messagin-cont{
    display: flex; 
    align-items: self-end; 
    justify-content: flex-end; 
    padding-right: 2%;
    .auction-button {
        border: 4px solid #FA8500; 
        border-radius: 20px;
        background-color: #FFFFFF;
        width: auto;
        .image-container{
            margin-right: 10px;
        }
        .label{
            font-size: 17px; 
            font-weight: 500; 
            line-height: 41px; 
            letter-spacing: 0em;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #FA8500;
        }
    }
}

.auction-containers {
    height: 36px;
    border-radius: 2px;
    gap: 6px;
    background-color: #DAFFEB;
}

.image-container {
    display: flex;
    align-items: center;
}

.center-blue {
    .auction-container {
    margin: 0 10px;
}

    display: flex;
    align-items: center;
}

.auc-card-container{
    position: relative;
    width: 350px;
    .btn-label{
        position: absolute; 
        top: 25px; 
        left: 20px; 
        z-index: 1;
        width: fit-content;
        height: 40px;
        color: #FFFFFF;
        border-radius: 20px;
        &.active{
            background-color: #00B76E;
        }
        &.expired{
            background-color: #FF0000;
        }
        &.upcoming{
            background-color: #2dc3fd;
        }
        &.featured{
            background-color: #ffa200;
            color: #000000;
        }
    }
    .img-fluid{
        border-radius: 30px 30px 0 0;
    }
    .content-div{
        border: 1px solid #D2D2D2; 
        padding: 10px; 
        border-top-color: transparent; 
        background-color: #ffff; 
        border-radius: 0 0 30px 30px;
        .flex-fill{
            width: 333px;
            padding: 5px;
            &.auc-title-cust{
                height: 50px;
            }
            a{
                text-decoration: none; color: #000000; font-size: 15px; font-weight: 900; display: inline-block; 
            }
            img{
                vertical-align: middle;
            }
        }
        .interactions{
            button{
                background-color: #E7F4F9;display: flex;align-items: center;justify-content: center;
                &.count{
                    background-color: #E7F4F9;width: 87px;font-weight: 900;;display: flex;align-items: center;justify-content: center;
                }
            }
        }
        .outer-div{
            width: 220px;
            height: 34px;
            border: 1px solid #D2D2D2;
            border-radius: 50px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .first{
                color: #00B76E;
                font-weight: 800;
            }
            .second{
                display: flex;
                gap: 5px;
                position: absolute;
                align-items: center;
                bottom: -5px;
                font-size: 5px;
                right: 25px;
            }
        }
        .contaiiner-amt{
            .col-one{
                height: auto; 
                width: auto; 
                background-color: #F7F9FF;
                span{
                    font-size: 0.7rem;
                    white-space: nowrap;
                    display: flex;
                    line-height: 1.3rem;
                }
            }
            .col-two{
                height: 120px; 
                width: 110px; 
                background-color: #F7F9FF;
                margin-left: 1px; 
                display: flex; 
                flex-direction: column; 
                align-items: flex-end;
                padding-top: 10px;
                .span-1{
                    font-size:small;
                    white-space: nowrap;
                    display: flex;
                }
                .span-2{
                    font-size: xx-small;
                    margin-left: 2px;
                    white-space: nowrap
                }
                .div-1{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 5px;
                    .date{
                        font-size:small;
                        margin-left: 2px;
                        white-space: nowrap;
                    }
                    .time{
                        font-size: xx-small;
                        margin-bottom: 12px;
                        white-space: nowrap;
                    }
                }
            }
            
        }
        .container-dates{
            .col-one{
                height: 50px; 
                background-color: #F7F9FF;
                .col-times{
                    height: 50px; 
                    background-color: #F7F9FF;
                    span{
                        font-size: xx-small; 
                        white-space: nowrap;
                        display: flex;
                    }
                }
                .col-dates{
                    height: 50px; 
                    background-color: #F7F9FF;
                    span{
                        font-size: xx-small; 
                        white-space: nowrap;
                        display: flex;
                    }
                }
            }
            .col-cont-details{
                height: 50px; 
                background-color: #F7F9FF;
                margin-left: 1px; 
                display: flex; 
                flex-direction: column; 
                align-items: flex-end; 
                padding-top: 0px;
                .cont-tot-auc{
                    height: 39px; 
                    background-color: #A4D4E2; 
                    width: 45px; 
                    border-radius: 5px; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    img{
                        margin-left: 4px;
                    }
                    p{
                        margin: 0; 
                        transform: translate(-10px, -5px);
                    }
                }
                .col-cal-cont{
                    height: 40px; 
                    background-color: #A4D4E2; 
                    width: fit-content; 
                    border-radius: 5px; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    margin-left: 4px;
                    p{
                        margin: 0; 
                        transform: translate(2px, 0px);
                    }
                }
            }
            .cont-list-auc{
                height: 50px; 
                background-color: #F7F9FF;
                .btn-cont{
                    height: 29px; 
                    background-color: #5BB388; 
                    width:120px; 
                    border-radius: 25px; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    a{
                        margin: 0; 
                        margin-left: 5px;
                        font-size: 0.8rem;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }
                .btn-cont-2{
                    height: 29px; 
                    background-color: #FF8819; 
                    width:55px; 
                    border-radius: 25px; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    a{
                        margin: 0; 
                        margin-left: 3px;
                        margin-top: 3px;
                        font-size: 10px;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }
            }
.social-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    .social-icons {
        .socials{
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            padding: 0;
            li{
                padding: 5px;
                a{
                    cursor: pointer;
                }
            }
        }
    }
}
            
            .btn-final-aaproval{
                background-color: #F7C193; 
                color: #000000; 
                border-radius: 50px; 
                height: 25px; 
                width: auto;
                font-size: 14px;
            }
        }
    }
}

a {
    text-decoration: none;
    color: #FFFFFF;
    /* padding-right: 4px; */
}

.bg-banner, .bg-banner-alt{
    background: rgb(186,112,171) !important;
    background: linear-gradient(61deg, rgba(186,112,171,1) 4%, rgba(115,63,63,1) 25%, rgba(115,63,63,0.8562018557422969) 44%, rgba(115,63,113,0.9206276260504201) 66%, rgba(115,63,63,1) 86%) !important;
    .banner-image-cust{
        height: 370px!important;
    }
}

.auc-card-wrapper{
    .slick-slider{
        .slick-list{
            margin: auto;
            width: 77%;
        }
    }
}

/* doubel-------------------------ornage==================== */
.double-main {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    overflow: hidden;
}

.container {
    max-width: 98% !important;
}
.container-1,
.container-2 {
    padding: 10px;
    margin: 0 10px;
}

.hot-auction-button {
    background-color: #FFE1C8;
    border: none;
    width: 165px;
    color: black;
    height: 30px;
    border-radius: 50px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.p-contents {
    font-weight: 500;
    text-align: left;
    margin: 5px 0;
    color: #000000;
}

.hot-p {
    font-weight: 900;
    margin-top: 10px;

}

.highest {
    background-color: #FFFFFF;
    border: none;
    font-weight: 900;

    display: flex;
    align-items: center;
    justify-content: center;
}
.ha-data {
    display: flex;
    justify-content: space-between;
    margin: 0 50px 0 20px;
    border-bottom: 0.5px solid gray;
}

/* **********************************RAHUL'S STYLES******************************* */
.nav-menu a {
    font-size: 14px;
    padding: 2px 
}

.ha-heading {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    width: 100%; 
    padding: 0 40px 0 20px;
}


.nav-menu a:last-child {
    font-size: 10px;
}

.our-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #D2F2FF;
    margin-top: 100px;
    padding-top: 80px;
    padding-bottom: 150px;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
}

.our-partners-heading {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px;
}

.logos {
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
    // &::before {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 150px;
    //     background: linear-gradient(to right, #D2F2FF, transparent);
    //     z-index: 2;
    //     content: "";
    // }
    // &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 150px;
    //     background: linear-gradient(to left, #D2F2FF, transparent);
    //     z-index: 2;
    //     content: "";
    // }
}
.overlay{
    &::before{
        background: linear-gradient(to right, #D2F2FF, transparent) !important;
    }
    &::after{
        background: linear-gradient(to right, #D2F2FF, transparent) !important;
    }
}
.logos-slide {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    animation: 20s slide infinite linear;
}

.logos-slide img {
    margin-right: 25px;
    margin-left: 25px;
}
.mzad-footer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 2%;
    padding: 6rem 4rem;
    background-color: #254f5f;
    color: white;
}
.footer-text {
    font-size: 12px;
}

.support-button {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 10;
    button {
        position: relative;
        background-color: #232323;
        color: #fff;
        border: 1px solid #fff; 
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 8px 15px;
    }
    img {
        width: 30px !important; 
        height: auto; 
        margin-right: 10px; 
    }
}

.nav-link {
    font-size: 12px !important;
}

.container-w100{
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.black-arrow-button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

}

.container-1 {
    height: 178px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.black-arrow-container {
    align-self: flex-start;

}

.black-contents {
    margin-bottom: 5px;
    font-weight: 500;
    text-align: left;
    margin: 5px 0;
    color: #000000;

}

.month-div {
    font-size: 7px;
    width: 30px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 70px;

}

.loss,
.gross {
    display: inline;
    font-size: 10px;
}

.loss {
    color: #F00808;
    margin-left: 1px;
}

.gross {
    color: #13C650;
}

.card {
    margin-bottom: 20px;
}

#manu {
    width: 61px;
    height: 36px;
    background-color: #00B76E;
    color: #FFFFFF;
    border-radius: 20px;
}

.card-img-top {
    height: 200px;

    width: 1000px;
}

.button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
}

.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
}

.btn {
    margin: 2px;
}

.inner-section {
    width: 35px;
    height: 12px;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #00B76E;
}

.auction-container-number {
    width: 35px; 
    height: 20px; 
    display: flex; 
    align-items: center;
    justify-content: center;
    background-color: #00B76E; 
    color: white;
    font-weight: bold;
    font-size: 12px;
    margin-right: 5px;
}
/* *************************************HEADER STYLES*************************************** */
.header-main {
    display: flex;
    justify-content: space-between;
    padding: 0 2% 0 2%;
    height: 125px;
    background-color: white;
}
.header-nav-menu {
    display: flex;
    align-items: flex-end;
    padding: 0.5% 0 1% 0;
}
.header-lang{
    a{
        font-size: 12px;
        img{
            height: 15px;
        }
    }
}
.nav-list {
    display: flex;
}
.nav-list li {
    list-style: none;
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.nav-list li a {
    text-decoration: none;
    color: black;
}
.header-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-end;
    .nav-btn{
        &.color-1{
            background-color: #82dfff;
        }
        &.color-2{
            background-color: #ffc12a;
        }
        &.color-3{
            background-color: #ff8484;
        }
    }
}
.header-date-lang {
    display: flex;
    gap: 20px;
    align-items: center;
    span{
        font-size: 0.8rem;
        padding: 0.2rem;
    }
}
.country-notif-guest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 62px;
}
.country {
    display: flex;
    align-items: center;
    gap: 2px;
}

.country-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.country-name span:first-child {
    font-size: 5px;
    color: gray;
}
.country-name span:last-child {
    font-size: 10px;
}
.noti-guest {
    display: flex;
    align-items: center;
    gap: 10px;
}
.search-login-reg-div {
    display: flex;
    gap: 10px;
}
.nav-btn {
    background-color: white;
    border: 1.5px solid black;
    border-radius: 5px;
    color: black;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.welcome-staff {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 6px 8px;
}
.wallet-btn {
    width: auto;
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: center;
}
.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    background-color: #00a9e4;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 2px 20px;
    height: 35px;
}
.reg-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #ef2d28;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 2px 15px;
    height: 35px;
}
.header-search {
display: flex;
}
.header-search input {
border: none;
}
.header-search input:focus {
outline: none;
border-bottom: 0.5px solid gray;
}
.header-search-btn {
background: none;
border: none;
}
.logo-div {
height: 100%;
display: flex;
justify-content: center;
align-items: center;
width: 150px;
box-shadow: -4px 0 6px rgba(0, 0, 0, 0.1), 4px 0 6px rgba(0, 0, 0, 0.1);
}

/* ****************************TAB-MOBILE HEADER STYLE******************** */
.header-tab-mobile {
padding: 10px 20px;
width: 100%;
background-color: white;
}
.date-country-lang {
display: flex;
justify-content: flex-end;
gap: 10px;
margin-bottom: 30px;
}
.logo-noti-menu {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}
.noti-menu {
display: flex;
gap: 10px;
align-items: center;
}
.dropbtn {
background: none;
color: white;
padding: 5px;
font-size: 16px;
border: none;
}
.dropbtn img {
width: 20px;
}

.dropdown {
position: relative;
/* display: inline-block; */
display: none;
}

.dropdown-content {
display: none;
position: absolute;
background-color: white;
min-width: 320px;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
z-index: 1000;
right: 5%;
border-radius: 10px;
overflow: hidden;
padding: 20px 10px;
}

.dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.dropdown-content a:hover {
background-color: #ddd;
}

.dropdown:hover .dropdown-content {
display: block;
}

.dropdown:hover .dropbtn {
border: 1px solid black;
border-radius: 5px;
}
.mobile-welcome-guest-role {
display: flex;
justify-content: space-around;
align-items: center;
margin-bottom: 10px;
}
.mobile-login-reg {
display: flex;
justify-content: space-around;
align-items: center;
margin-bottom: 20px;
}
.mobile-login-reg button {
height: 30px;
}
.mobile-wallet {
width: 100%;
display: flex;
justify-content: center;
height: 40px;
}
.mobile-wallet button {
width: 80%;
}
.header-tab-mobile {
display: none;
}
.dropdown-items .header-search {
display: flex;
width: 100%;
justify-content: flex-end;
margin-bottom: 20px;
padding-right: 20px;
}
.dropdown-items .header-search input {
width: 220px;
border-bottom: 1px solid black;
}
.dropdown-items .header-search img {
width: 20px;
}

/* *************************************HEADER STYLES*************************************** */
.social-icons-container {
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px;
}
.social-icons {
display: flex;
justify-content: space-around;
align-items: center;
}
/* *****************************MOB DROPDOWN******************************** */
.mobile-search-filter {
display: none;
} 
.mob-search-field {
border-radius: 50px;
border: 1px solid black;
padding: 5px 5px 5px 10px;
display: flex;
justify-content: space-between;
align-items: center;
width: 95%;
}
.mob-search-field input {
border: none;
background: none;
width: 75%;
}
.mob-search-field input:focus {
outline: none;
}
.mob-search-field img {
width: 25px;
}
.mob-search-btn {
background-color: #F7C193;
color: black;
border: none;
border-radius: 50px;
padding: 10px 20px;
}
.mob-dropbtn {
background-color: #FA8500;
color: white;
padding: 16px;
font-size: 16px;
border: none;
border-radius: 50%;
cursor: pointer;
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
}
.mob-dropbtn img {
width: 15px;
}

.mobile-dropdown {
position: relative;
display: inline-block;
}

.mob-dropdown-content {
display: none;
position: absolute;
background-color: white;
min-width: 260px;
right: 10px;
border-radius: 10px;
overflow: hidden;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
}

.mob-dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.mob-dropdown-content a:hover {background-color: #f1f1f1}

.mobile-dropdown:hover .mob-dropdown-content {
display: block;
}

.dropdown:hover .mob-dropbtn {
background-color: #3e8e41;
}
/* *****************************MOB DROPDOWN******************************** */

/* ***********************************SIDEBAR STYLE****************************** */
.offcanvas {
    width: 300px !important;
}
.sidebar-btn {
    background-color: white !important;
    border: 1px solid black !important;
}
.offcanvas-top-section {
    background-color: #254F5F !important;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 20px;
    position: relative;
    gap: 5px;
}
.btn-close {
    position: absolute !important;
    top: 30px !important;
    right: 35px !important;
    background-color: white !important;
    border-radius: 50% !important;
    height: 25px !important;
    width: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
}
#support-modal{
    .modal-header{
        .btn-close{
            position: absolute !important;
            top: 15px !important;
            right: 15px !important;
            background-color: black !important;
            border-radius: 50% !important;
            height: 20px !important;
            width: 20px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            .fa{
                color: #FFF !important;
            }
        }
    }
    .model_body_content_desktop {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .model_body_content_mobile {
        display: none;
    }
    .button_flex {
        display: flex;
        justify-content: center;
        align-items: center;
        .oval-button {
            width: 100px;
            height: 40px;
            background-color: #404040;
            text-align: center;
            border-color: #404040;
            border-radius: 25px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            .button_text {
                color: #FFF;
                text-align: right;
                font-size: 16.601px;
                font-style: normal;
                font-weight: 500;
                line-height: 237.5%;
                text-wrap: nowrap;
            }
        }
    }
}
.offcanvas-user {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
}
.offcanvas-user img {
    width: 35px;
}
.offcanvas-welcome {
    display: flex;
    gap: 20px;
    color: white;
}
.welcome-guest {
    font-weight: bold;
}
.offcanvas-welcome-edit img {
    width: 8px;
}
.offcanvas-welcome-edit a {
    font-size: 8px;
    text-decoration: underline;
    color: white;
}
.offcanvas-role {
    color: white;
}
.offcanvas-wallet {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3px;
    background-color: #EF2D28;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
}
.offcanvas-wallet img {
    width: 20px;
}
.offcanvas-body {
    padding: 0 0 !important;
}
.offcanvas-auctions, .offcanvas-mid-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    border-bottom: 1px solid rgb(183, 183, 183);
    padding: 8px 0px;
}
.offcanvas-bottom-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-top: 8px;
}
.offcanvas-auctions a, .offcanvas-mid-section a, .offcanvas-bottom-section a{
    color: black;
    padding: 5px 25px;
    text-decoration: none;
    display: block;
}
/* ***********************************SIDEBAR STYLE****************************** */
/* ***********************************BLUE NAV DROPDOWN******************************* */
.mobile-blue-nav-container {
    display: none;
    margin-top: 2rem;
    .nav-dropbtn {
        &:hover{
            background-color: #daecf8 !important;
            border-radius: 30px !important;
        }
        .dropdown{
            width: 100%;
            .btn{
                background-color: rgba(0, 0, 0, 0);
                border: none;
                width: 100%;
                color: #000000;
            }
        }
    }
}
.blue-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blue-dropdown-item-left {
    display: flex;
    align-items: center;
    gap: 5px;
}
.blue-dropdown-item-left-btn {
    background-color: #DAFFEB;
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
    border-radius: 30px;
    gap: 5px;
}
.blue-dropdown-item-left-btn a {
    text-decoration: none;
    color: black;
}
.nav-dropbtn {
    background-color: #daecf8;
    color: #0a0a0a;
    padding: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 250px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-dropbtn-arrow {
    width: 10px;
}

.nav-dropdown {
position: relative;
display: inline-block;
}

.nav-dropdown-content {
display: none;
position: absolute;
background-color: #1A1E42;
color: white;
min-width: 250px;
width: fit-content;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
padding: 15px 15px;
border-radius: 0px 0px 30px 30px;
}

.nav-dropdown-content a {
color: white;
padding: 12px 16px;
text-decoration: none;
display: block;
font-size: 12px;
}

.nav-dropdown-content a:hover {color: #0068C9}

.nav-dropdown:hover .nav-dropdown-content {
display: block;
}

.nav-dropdown:hover .nav-dropbtn {
background-color: #10153e;
border-radius: 30px 30px 0px 0px;
}

.nav-dropdown:hover .nav-dropbtn-arrow {
transform: rotate(180deg);
}

.auction-tabs{
    width: 90%;
    margin: auto;
    .auction-tab-item{
        height: 36px;
        background-color: #1A1E42;
        cursor: pointer;
        &:hover{
            background-color: #DAFFEB;
            a{
                color: #000000;
            }
        }
        &.is-active{
            background-color: #DAFFEB;
            a{
                color: #000000;
            }
        }
    }
}

.auction-card-img-cont{
    height: 165px;
    overflow: hidden;
    position: relative;
    border: 1px solid #D2D2D2;
    border-bottom: 1px solid #f8f8f8;
    border-radius: 30px 30px 0px 0px!important;
    background-color: #FFFFFF;
    img{
        border-radius: 30px 30px 0px 0px!important;
        height: auto!important;
        min-width: 100%;
        object-fit: cover;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        transition: -webkit-transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease,-webkit-transform .3s ease;
        width: auto!important;
    }
    &:hover{
        img{
            transform: scale(1.1);
        }
      }
}

.btn-pn{
    font-size: 0.6rem;
    position: absolute;
    z-index: 9;
    right: -50px;
    top: -5px;
}
.span-pn{
    position: relative;
}
.auctions-marketplace-div{
    width: 80%;
    #inner-div-double {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
        border-style: solid;
        border-radius: 30px;
        border-color: #F7C193;
        border-width: 5px 5px 10px 5px;
        background-color: #FFFFFF;
        .double-container-hot-auction {
            border-radius: 20px;
            border-style: solid;
            border-width: 1px;
            border-color: #000000;
            height: 188px;
            width: 100%;
            padding: 10px 20px;
        }
    }
    
    .inner-div-double2 {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
        border-style: solid;
        border-radius: 30px;
        border-color: #F7C193;
        border-width: 5px 5px 10px 5px;
        .double-container {
            border-radius: 20px;
            border-style: solid;
            border-width: 1px;
            border-color: #000000;
            height: 188px;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
    }
}
.w-85{
    width: 85% !important;
}
.w-100{
    width: 100% !important;
}