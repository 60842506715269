.page-container{
  .wrapper{
    position: relative;
  }
  .password-eye {
    color: #000;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    z-index: 99;
  }
  .custom-input-half {
    width: 50%;
  }
  .custom-input-quater {
    width: 25%;
  }
  .btn-color {
    background-color: orange;
    color: white;
  }
  .horizontal-line {
    border-top: 2px solid #ddd; /* Adjust the border color and thickness as needed */
    text-align: center;
    margin-top: 20px; /* Adjust the margin as needed */
    margin-bottom: 20px; /* Adjust the margin as needed */
  }

  .line-text {
    display: inline-block;
    background-color: #fff; /* Adjust the background color as needed */
    padding: 0 10px; /* Adjust the padding as needed */
    position: relative;
    top: -12px; /* Adjust the position as needed */
    color: #a6a6a6;
    font-size: 0.9rem;
  }
  .icon-size {
    width: 25px;
  }
  .custom_forgetpassword {
    color: #909090;
    font-size: 10.699px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom_btn-color {
    background-color: #1a1e42;
    border-color: #1a1e42;
  }
  .custom_btn-color:hover {
    border-color: #1a1e42 !important;
    background-color: white;
  }
  .custom_text{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom_text2 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom_text3 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .custom_text4 {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
  }
  .custom_text5 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .custom_text6 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  .custom_text7 {
    color: red;
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
  }
  .custom_text7_green {
    color: #00B76E;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .custom_text8{
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .custom_text9 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .custom_text13 {
    color: #46BCFF;
    text-align: center;
    font-size: 14.004px;
    font-style: normal;
    font-weight: 700;
  }
  .custom_bgcolor {
    background-color: #A4D4E2 !important;
  }
  .custom_btn-color2 {
    background-color: #46BCFF !important;
    border-color: #46BCFF !important;
    border: 1px solid #FFF !important;
  }
  .custom-bg-color2 {
    background-color: #F7C193 !important;
}
  .custom-text {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
  .custom-text:hover {
    color: #1a1e42 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
  .custom-text2 {
    color: #fa8500;
    font-size: 14.265px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  .custom-text3 {
    color: #ef2d29;
    font-size: 14.265px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .custom-text4 {
    color: #000;
    font-size: 14.265px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  .active_cus{
    position: absolute;
    background-color: #4CAF50;
    margin-top: 1vh;
    margin-left: 2vh;
    z-index: 999;
    &.success{
      background-color: #4CAF50;
    }
    &.danger{
      background-color: #ff0000;
    }
    &.info{
      background-color: #0080ff;
    }
    &.warning{
      background-color: #ff8400;
    }
    span{
      color: #FFF;
    }
  }
  .Bid_Count_div {
    position: absolute;
    bottom: 5vh;
    left: 5vh;
  }
  .custom-outline-button {
    color: #00aced;
    border-color: #00aced;
  }
  .custom-outline-button :hover {
    background-color: #00aced;
    color: white;
    border-color: #00aced;
  }


  .image-side {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      overflow: hidden;
      //margin-left: 12px;
    }
  }
  .custom-margin {
    margin-left: 20px;
  }
  .custom-text5 {
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .blue-container {
    padding-top: 20px;
    margin-bottom: 100px;
    background-color: #00aced;
    border-radius: 20px 20px 15px 15px;
  }
  .custom-input{
    background-color: #F7F9FF !important;
    border: 1.031px solid #D2D2D2;
  }

  .support-button{
    visibility: hidden;
  }
  .canvas_width{
    width: 50% !important;
  }

  .blue-div-container {
    padding-top: 20px;
    margin-top: 50px;
    background-color: #46BCFF;
    border-radius: 15px;
    margin-bottom: 100px;
  }
  .blue-div-inner-container {
    background-color: white;
    margin-top: 15px;
  }
  .blue-div-container-title {
    margin-left: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blue-div-container-title span{ 
    color: white;
    font-size: 25px;
  }   
  
  .custom-button-color {
        background-color: #46BCFF !important;
    }
    .custom-button-color1{
        background-color: #fcfcfc !important;
    }

    .vertical-oval {
        width: 55%; 
        height: 20%;
    }
    .vertical-oval_mobile {
      width: 100%; 
      height: 20%;
    }
    .form-control{
        background-color: #F7F9FF !important;
        border: 1.031px solid #D2D2D2;
    }
    .bg-disabled{
      background-color: #D2D2D2 !important;
    }
    #User_guide{
      color: #EF2D29;
      font-family: SF Pro Display;
      font-weight: 400;
      font-size: 14.27px; 
    }
    .button-icon-size {
        width: 20px;
        margin-right: 5px;
    }
    .button-icon-size-individual {
        width: 10px;
        margin-right: 5px;
    }
    .custom-div_color {
        background-color: #EAF2F5 !important;
    }
    .company{
        background-color: #46BCFF !important;
        color:white;
    }

    .auction-details-section{
      padding-left: 7.5rem;
      padding-right: 7.5rem;
      .details-toggle-icon{
        i{
          background: #a4d4e2;
          padding: 0.3rem 0.5rem;
          border-radius: 50px;
        }
      }
      .bidding-comp{
        .card-body{
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .custom_btn-color3 {
        background-color: #46BCFF !important;
        border-color: #46BCFF !important;
        z-index: 999;
      }
      .sliderCountIcon{
        color: #69c0d9;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
      }
      .sliderCount{
        border-color: #A4D4E2 !important;
        color: #000;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 800;
        padding: 0.3rem 1rem;
      }
      .o-hidden {
        overflow: hidden !important;
        padding: 0.5rem;
      }
      .bid-amounts{
        .btn{
          &.active{
            background-color: #FFA5A5 !important;
          }
        }
      }
      .socials{
        margin: 0;
        display: flex;
        list-style: none;
        justify-content: space-evenly;
          li{
            button{
              svg{
                height: 35px;
                width: 35px;
              }
            }
          }
      }
      .details-icon{
        margin: 0.1rem;
      }
      .details-title{
        margin: 0.2rem;
      }
      .ad-head{
        position: relative;
        z-index: 99;
        background: #f7f9ff !important;
        cursor: pointer;
      }
      .card-details{
        border: 1px solid #e3e3e3;
        min-height: 50px;
        padding: 1rem;
        background: #ffffff;
        border-radius: 0rem 0rem 1rem 1rem;
        margin-top: -0.8rem;
        z-index: 9;
      }
      .custom_text10{
        color: #000;
        font-style: normal;
        font-size: 18px;
        font-weight: 500;
      }
      .documents{
        i{
          font-size: 3rem;
        }
        .button_colour{
          background: #000;
          color: #FFF;
        }
      }
    }
}
.page-container{
  .custom_btn-color{
      background-color: #1a1e42 !important;
      border-color: #1a1e42 !important;
  }
}

#page-container-otp-login{
  .content {
    padding: 0px 0;
    margin-bottom: 50px;
    background: url("../../public/assets/images/content-bg.jpg");
    background-size: cover;
    width: 100%;
    display: grid;
    place-items: center;
    .main-container {
      width: 75%;
      padding-top: 10px;
      background-color: #46bcff;
      border-radius: 20px;
      .container-header {
        margin-bottom: 10px;
        margin-left: 40px;
        span{
          color: white;
          font-size: 20px;
        }
      }
      .horizontal-line {
        height: 0.8px;
        background-color: gray;
        width: 100%;
        position: absolute;
     }
      .inner-container {
        background-color: white;
        border-radius: 20px;
        display: grid;
        grid-template-columns: 5fr 4fr;
        gap: 20px;
        .left-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 30px;
            padding-bottom: 30px;
            .send-otp-on {
              position: relative;
              height: 50px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
          }
          .buttons {
              display: flex;
              gap: 30px;
              margin-top: 20px;
              .sms-btn {
                display: flex;
                align-items: center;
                gap: 20px;
                height: 40px;
                justify-content: center;
                border: 2px solid #9747ff;
                width: 180px;
                color: #9747ff;
                background-color: white;
                padding: 10px 25px;
                border-radius: 50px;
                img {
                  width: 20px;
                }
              }
              .whatsapp-btn {
                display: flex;
                align-items: center;
                gap: 20px;
                height: 40px;
                justify-content: center;
                border: 2px solid #13c650;
                width: 180px;
                color: #13c650;
                background-color: white;
                padding: 10px 25px;
                border-radius: 50px;
                img {
                  width: 20px;
                }
            }
          }
        }
        .send-otp-on {
          position: relative;
          height: 50px;
          width: 65%;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: #fa8500;
            background-color: white;
            z-index: 1;
            padding: 0 10px;
          }
          .horizontal-line {
            height: 0.8px;
            background-color: gray;
            width: 100%;
            position: absolute;
          }
        }
      }
    }
    
}
  
}

#auction-grouped{
  max-width: 100% !important;
  .custom-input-half {
    width: 50%;
  }
  .custom-input-quater {
    width: 25%
  }
  .btn-color {
    background-color: orange;
    color: white;
  }
  .horizontal-line {
    border-top: 2px solid #000000;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    transform: rotate(90deg);
  }

  .line-text {
    display: inline-block;
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    top: -12px;
  }
  .icon-size {
    width: 25px;
  }
  .icon-size-new {
    width: 22px;
  }
  .wpa{
    color: red;
  }
  .reject{
    font-weight: bold;
    text-decoration: underline;
    color: red;
  }
  .approve{
      text-decoration: underline;
      color: green;
  }
  .margin-top {
    margin-top: 10px;
  }
  .content {
    height: fit-content;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .license-plate{
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    width: fit-content;
    height: 60px;
    justify-content: space-evenly;
    align-items: baseline;
    background-color: #FFE1C8;
    border-radius: 40px;
    section{
      padding: 8px 10px; background-color: white; border-radius: 20px; display: grid; place-items: center;
    }
    span{
      font-weight: bold; font-size: 24px;
    }
  }
  .license-plate h4 {
    font-weight: bold;
    font-size: 20px;
    font-family: 'SF Pro Display', sans-serif;
  }
  .reg-tabs{
    width: 75%;
    height: 50px;
    background-color: white;
    border: 1px solid #D2D2D2;
    border-radius: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .sa-details{
      margin-top: 15px; font-weight: 600;
    }
  }
  .reg-tab-item {
    display: flex; 
    align-items: center; 
    height: 100%; 
    gap: 15px;
  }
  .reg-tab-inner-item {
    background-color: #9FE9FF; 
    display: grid; 
    place-items: center; 
    width: 40px; 
    height: 35px; 
    border-radius: 25px;
  }
  .vertical-line {
    display: inline-block;
    margin: 0;
    width: 1px;
    height: 75%; 
    background-color: #000;
    border: none;
  }
  .button-sort-section {
    width: 76%;
    margin-top: 20px !important;
    margin-bottom: 1px !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .buttons-section {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 1px solid #D2D2D2;
    border-radius: 40px;
    height: 55px;
    padding: 0px 10px;
    overflow-x: auto;
    gap: 0.5rem;
  }
  .category-buttons{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 0.5rem;
  }
  .sort-section {
    width: 39%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9FE9FF;
    border: 1px solid #D2D2D2;
    border-radius: 40px;
    height: 55px;
    padding: 0px 10px;
    gap: 5px !important;
  }
  .sort-box-btn {
    height: 40px !important;
    width: auto !important;
    text-wrap: nowrap;
    border-radius: 30px !important;
    border: 1px solid #D2D2D2;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 11px;
    &:hover {
      background-color: #9FE9FF;
      border: 1.5px solid black;
      color: black;
    }
    &.active{
      background-color: #9FE9FF;
      border: 1.5px solid black;
      color: black;
    }
  }
  .search-section-dropdown {
    display: none;
  }
  .burger-menu {
    display: none;
  }
  .search {
    background-color: white;
    padding: 5px 10px;
    height: 40px;
    width: 100%;
    border-radius: 40px;
    display: flex;
    align-items: center;
  }
  .search input {
    outline: none;
    border: none;
    border-radius: 40px;
    width: 92%;
  }
  .sort-button{
    img{
      &.grid-invert{
        filter: invert(1);
      }
    }
    &.sbtn-1{
      background-color: #002464;
      border: none;
      display: grid;
      place-items: center;
      border-radius: 50%;
      padding: 10px;
    }
    &.sbtn-2{
      background-color: white;
      border: none;
      display: grid;
      place-items: center;
      border-radius: 50%;
      padding: 10px 
    }
  }
  .sort-button-right {
    background-color: white;
    padding: 5px 25px;
    height: 40px;
    width: fit-content;
    border: 1px solid black;
    border-radius: 40px;
    display: flex;
    align-items: center;
  }
  .cards-section {
    width: 75%;
    height: fit-content;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .card{
      margin-bottom: 0px;
    }
  }

  .card {
    display: grid;
    grid-template-columns: 2fr 5fr 2fr;
    border-radius: 30px;
    overflow: hidden;
  } 
  .left-image {
    border-radius: 30px;
    overflow: hidden;
    width: 100%;
    margin-right: 20px;
    background: linear-gradient(to right, #278557, rgba(255, 255, 255, 0));
  }
  .pagination{
    text-align: right;
    position: relative;
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    .page-item{
      &.active{
        .page-link{
          background: #000;
          color: #FFF;
          font-weight: bold;
        }
      }
      a{
        cursor: pointer;
        border-radius: 0px !important;
        color: #000;
        font-weight: normal;
        span{
          color: #000;
          font-weight: normal;
        }
      }
    }
  }
  .left-image{
    img{
      width: fit-content;
      height: 190px;
      border-radius: 30px;
    }
  }
  .left-section {
    height: 85%;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .left-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    .auc-head{
      width: 70%;
    }
  }
  .left-bottom {
    display: flex;
    margin-top: 0px;
  }
  .plate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-content: center;
    background-color: #FEC00C;
    border: 1px solid black;
    border-radius: 10px;
  }
  .plate-span {
    border-right: 1px solid black;
    height: 60px;
    display: grid;
    place-items: center;
    font-weight: bold;
    font-size: 2rem;
    padding: 5px 10px;
  }
  .data-h3{
    font-weight: bold;
    font-size: 1.2rem;
  }
  .lot {
    font-size: 1rem;
  }
  .details-btn-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .details-btn-section p {
    color: #FA8500;
    font-weight: bold;
  }
  .details-button {
    background-color: #5BB388;
    color: white;
    border-radius: 20px;
    border: none;
    padding: 5px 20px;
    img{
      margin-top: -3px 
    }
    span{
      padding-right: 2px;
      padding-left: 2px 
    }
  }

  .lb {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0px 10px;
  }
  .left-bottom-one  {
    border-right: 1px solid #D2D2D2;
  }
  .left-bottom-two {
    border-right: 1px solid #D2D2D2;
    .location-outline{
      background-color: #E8E8E8; display: grid;place-items: center; border-radius: 50%; padding: 3px;
    }
  }
  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 7px 0px;
    border-left: 1px solid #ccc;
    .cur-amount{
      font-weight: bold; font-size: 12px;text-align: center;
    }
  }
  hr {
    margin: 0.5rem 0;
  }
  .bid-now-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E50A0A;
    border: none;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    height: 35px;
  }
  .showing-auction {
    width: 75%; 
    margin-top: 60px !important;
    padding-left: 15px;
    .showing-label{
      font-size: 20px;color: #FA8500;font-weight: 500;
    }
    .sa-details{
      margin-top: 15px; font-weight: 600;
    }
  }
  .mzad-footer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 2%;
    padding: 6rem 4rem;
    background-color: #254f5f;
    color: white;
  }
  .footer-text {
    font-size: 12px;
  }
}

#auctions-list{
  .head-section {
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    width: fit-content;
    height: 60px;
    justify-content: space-evenly;
    align-items: baseline;
    background-color: #FFE1C8;
    border-radius: 40px;
    z-index: 1;
    position: relative;
    section{
      padding: 8px 10px;
      background-color: white;
      border-radius: 20px;
      display: grid;
      place-items: center;
    }
    span{
      font-weight: bold;
      font-size: 24px;
    }
  }
  .sptb{
    padding-right: 10rem;
    padding-left: 10rem;
    overflow: hidden;
    .filter-product-checkboxs{
      display: flex;
      flex-direction: column;
      .custom-checkbox{
        input[type="checkbox"]{
          width: 1rem;
          height: 1rem;
        }
        .custom-control-label{
          a{
            padding: 0.5rem;
          }
        }
      }
    }
    .image-holder{
      overflow: hidden;
      img{
        width: 100%;
        overflow: hidden;
      }
    }
    .curr-amt-label{
      font-size: 12px !important;
    }
    .leading-tight{
      font-size: 12px;
    }
    .socials{
      margin: 0;
      display: flex;
      list-style: none;
      justify-content: space-between;
        li{
          button{
            svg{
              height: 35px;
              width: 35px;
            }
          }
        }
    }
  }
}

#whoweare{
  .card-img-top{
    width: 100%;
    height: auto;
  }
  @media (max-width:576px) {
    .sub-menu {
        display: none !important;
    }
    .carousel {
        padding: 0 10px !important;
    }
    .carousel-inner {
        border-radius: 20px !important;
    }
    .support-button {
        width: 150px;
        font-size: 10px;
        left: 8px;
    }
    .support-button img {
        width: 15px;
    }
    .mobile-search-filter {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 30px;
        gap: 10px;
    }
    .blue_div_hide{
        display: none;
    }
  }
  @media screen and (max-device-width: 476px) {
    .support-button{
      display: none;
    }
  }



  .oval{
      height: 60px;
      width: 209px;
      background-image: linear-gradient(to right,#0DABE2 ,#5ECAEA);
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .oval > span{
      color: white;
      font-size: 30px;
      font-weight: 500;
  }

  .who_are_we > span{
      color: black;
      font-size: 16px;
      font-weight: 400;
  }
  .story_num{
      background-image: linear-gradient(#A4D4E2,#A4D4E2,#A4D4E2,#43aacf 100%);
      box-shadow: 0px 8px 0px 0px #1990B2; 
      border-radius: 30px;
  }
  .group_img{
      display: flex;
      justify-content: space-between; 
  }
  .group_image_sub{
    display: flex;
    justify-content: space-evenly; 
  }
  .centerr{
      text-align: center;
  }
  .centerr > span{
      color: #03668B;
      font-size: 18px;
      font-weight: 400;
  }
  .centerr > h2{
    color: #133240;
    font-weight: 600;
  }
  .our_numbers{
      color: #005677 !important;
      font-size: 42px !important;
      font-weight: 600 !important;
  }
  .numbers{
      color:#083040 !important;
  }
  .card-body{
      text-align: center !important;
  }
  .card_flex{
      display: flex;
      gap: 50px;
  }
  .card_flex_secondry{
    gap: 25px !important;
    justify-content: center !important;
  }
  .card_text{
      font-size: 28px;
      font-weight: 500;
  }
  .card_text_small{
      font-size: 16px;
  }
  .card{
      border-color: #0E5E8A !important;
      border-radius: 30px !important;
  }

  .flex_center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cards{
    border: 1px solid #F0F2F3;
    border-radius: 30px;
  }
  .user_cards{
    border: 1px solid #F0F2F3;
    border-radius: 30px;
    width: 100%;
    height: auto;
  }
  .card-bodys{
    text-align: center !important;
    padding: 15px;

  }
  .card-text{
    font-size: 20px;
    font-weight: 600;
  }
  .card-text_sub{
    font-size: 16px;
    color: red;
  }
  .checked{
    color: orange;
  }
  .user_rate_flex{
    display: flex;
    justify-content: space-between;
  }
  .user_rate_name{
    font-size: 20px;
    font-weight: 500;
  }
  .user_rate_name_body{
    font-size: 14px;
  }
  .user_card_flex{
    display: flex;
    gap: 10px;
  }
  .img_size {
    max-width: 100%;
    height: auto;
  }
  .align_justify{
    text-align: justify;
  }
  .mobile{
    display: none;
  }
  @media screen and (max-device-width: 1200px) and (min-device-width: 992px){
    .img_size{
      width: 450px !important;
    }
      
  }
  @media screen and (max-device-width: 992px) and (min-device-width: 768px) {
    .img_size{
      width: 510px;
      height: 280px;
    }
    .group_image_sub{
      
      gap:50px;
      flex-wrap: wrap;
    }
    .col{
      margin-top: 20px !important;
    }
    .card_flex{
      flex-wrap: wrap;
    }
    .user_card_flex{
      flex-wrap: wrap;
    }
    .card_flex_secondry{
      gap: 25px !important;
      justify-content: center !important;
    }
    
      
  }
  @media screen and (max-device-width: 767px) {
    
    .align_center{
      display: flex;
      justify-content: center;
    }
    .group_image_sub{
      
      gap:40px;
      flex-wrap: wrap;
    }
    .col{
      margin-top: 20px !important;
      flex-wrap: wrap !important;
    }
    .card_flex{
      flex-wrap: wrap;
    }
    .user_card_flex{
      flex-wrap: wrap;
    }
    .card_flex_secondry{
      gap: 25px !important;
      justify-content: center !important;
    }  

    .oval{
      height: 30px;
      width: 100%;
      
    }
    .oval > span{
        color: white;
        font-size: 20px;
        font-weight: 500;
    }
    .col-md-6:nth-child( even ){
      margin-top: 40px !important;
    }
    .who_are_we > span{
      color: black;
      font-size: 14px;
      font-weight: 400;
  }
  }

  @media screen and (max-device-width:415px) {
    .img_size{
      width: 345px;
    }
    .mobile{
      display: block;
    }
    .desktop{
      display: none;
    }
    
  }
}