
@media (max-width: 1446px) {
    .nav-list li a {
        font-size: 0.9rem;
    }
    .header-search input {
      width: 175px;
      border-bottom: 1px solid #ccc;
    }
    .nav-btn {
        font-size: 12px;
    }
    .login-btn {
        font-size: 12px;
    }
    .reg-btn {
        font-size: 12px;
    }
}

@media (max-width: 1186px) {
  .fs-5rem-mob{
    font-size: 0.6rem !important;
  }
  .fs-7rem-mob{
    font-size: 0.7rem !important;
  }
  body{
    background-color: #ffffff !important;
    background-image: none !important;
  }
    .d-mob-none{
      display: none !important;
    }
    .show_mobile{
      display: block !important;
  }
  .sptb{
    &.mx-5{
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  }
    .nav-list li a {
        font-size: 10px;
    }
    .header-search input {
        width: 60px;
    }
    .nav-btn {
        font-size: 10px;
    }
    .login-btn {
        font-size: 10px;
    }
    .reg-btn {
        font-size: 10px;
    }
    .header-date-lang {
        font-size: 12px;
    }
    .footer-message{
      display: none !important;
    }
    .double-main {
      display: none !important;
    }
    .sptb{
      //margin-right: 0.5rem !important;
      //margin-left: 0.5rem !important;
      .mt-7{
        margin-top: 2rem;
      }
      .card {
        .card-body{
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
    }
    .dash-container{
      margin-top: -2.7rem !important;
      .dash-container-head{
        background: #f0f0f0;
        padding: 0.8rem 0.8rem;
        border-radius: 1rem 1rem 0rem 0rem;
      }
      .item2-gl-nav{
        padding: 0 !important;
        .d-sm-flex{
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 0.7rem;
        }
      }
    }
    .grid_container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 2fr));
      grid-column-gap: 10px;
      column-gap: 10px;
      grid-row-gap: 10px;
      row-gap: 10px;
      .flex_container {
        .inner_round{
          border-radius: 10px;
          background-color: white;
          padding: 13px;
        }
        .outer_text {
          color: #000;
          font-size: 13px;
          font-weight: 400;
          span {
            display: flex;
            color: #000;
            font-size: 20px;
            font-weight: 700;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
    }

    .grid_container_below {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      column-gap: 10px;
      .outer_circle {
          border-radius: 4rem;
          width: 100%;
          height: 90%;
          padding: 1vh;
          .inner_circle {
            border-radius: 50%;
            padding: 10px;
            img {
              height: 16px;
              width: 18px;
            }
          }
          span{
            font-size: 10.5px;
            display: flex;
          }
      }
  }
  #auctions-list 
  {
    .sptb{
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      .items-toggler{
        display: none;
      }
      .tab-content{
        padding: 0.5rem 0.5rem !important;
        margin-top: 0rem !important;
        .row{
          display: flex;
          flex-direction: column;
          .col-3{
            width: 100%;
          }
          .col-6{
            width: 100%;
          }
        }
        .details-btn-cont{
          padding: 1rem;
          .details-btn{
            display: block;
            width: 100%;
            margin: 1rem;
          }
        }
      }
    }
  }
  .slick-dots{
    display: none !important;
  }
}

@media (max-width: 1046px) {
    .nav-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    .nav-list li a {
        font-size: 8px;
    }
    .header-date-lang {
        font-size: 8px;
        padding-top: 8px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .double-container {
        height: 220px;
        width: 100%;
    }
    .double-container-hot-auction {
        height: 220px;
        width: 100%;
    }
    .auc-card-wrapper{
        .slick-slider{
            .slick-list{
                margin: auto;
                width: 100%;
            }
        }
    }
}

@media (max-width:1024px) {
    .nav-menu a {
        font-size: 10px;
    }
    .nav-menu img {
        width: 10px;
        height: 8px;
    }
    .auctions-marketplace p {
        font-size: 8px !important;
    }
}

@media (max-width:990px) {
  .wallet-dash{
    .section-header {
        .header-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
    }
    .dropdown-section {
      overflow: scroll;
      .form-select {
        font-size: 10px;
      }
    }
    .wallet-dropdown-section{
      gap: 1px !important;
      padding: 10px 22px;
      .col-mob{
        width: 22%;
        padding: 0;
        .input{
          font-size: 10px;
        }
      }
      .col-mob-btn{
        width: 25px;
        padding: 0;
        margin: 0;
        button{
          padding: 0;
          margin: 0;
          img{
            width: 25px;
          }
        }
        
      }
    }
    .payment-info-section{
      overflow: scroll;
      .payment-info-container {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 65px;
        .payment-info-text {
          font-size: 10px;
          border-radius: 5px;
        }
      }
    }
}
  .mzad-footer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 1rem 1rem;
    justify-content: center;
    align-items: center;
  }
  .container{
    max-width: 100% !important;
  }
  .w-desk-75{
    width: 100% !important;
  }
  .slick-slider{
    .slick-arrow{
        &.slick-prev{
          left: 1rem;
        }
        &.slick-next {
          right: 1rem;
        }
    }
    .banner-image-cust{
      height: 130px !important;
    }
  }
  .auction-details-section{
    margin-top: 1rem;
    .details-title {
      margin: 0.2rem;
      font-size: 0.8rem;
    }
    .details-toggle-icon i {
      background: #a4d4e2;
      padding: 0.2rem;
      font-size: 0.5rem;
      border-radius: 50px;
      font-weight: bold;
    }
    .ad-head{
      padding: 0.25rem!important;
      padding-right: 1rem!important;
      padding-left: 1rem!important;
    }
  }
  #support-modal{
    .button_flex{
      .oval-button {
        .button_text {
          font-size: 14px;
          line-height: 10px;
        }
      }
    } 
    .support-main{
      display: block;
      .col-3, .col-2{
        width: 100%;
        .user-brd{
          margin-bottom: 1rem;
        }
      }
    }
    .model_body_content_desktop {
      display: none;
    }
    .model_body_content_mobile {
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: block;
      margin-bottom: 1rem;
    }
  }
  .enroll{
    .btn-toggle-custom{
      height: 50px;
      .btn{
        font-size: 0.8rem;
        height: 45px;
      }
    }
  }
  .mx-full{
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .auction-button {
    span{
        font-size: 22px !important;
    }
  }
  .header-main{
    height: 150px;
  }
  #wallet-manage{
    h4{
      font-size: 1rem !important;
    }
    .inpt-amount{
      font-size: 1rem !important;
      width: 100%;
      padding: 0.5rem !important;
    }
    .toggler{
      .btn{
        font-size: 0.7rem !important;
        height: 30px !important;
        &.px-4{
          padding-right: 0.75rem!important;
          padding-left: 0.75rem!important;
        }
      }
    }
  }
  
#auction-grouped {
    .content{
      padding-top: 0.5rem;
    }
    .showing-auction {
      width: 100%;
      margin-top: 10px !important;
      padding-left: 0px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
    }
    .reg-tabs{
      width: 100%;
      height: 30px;
      .reg-tab-item{
        gap: 1px;
        .reg-tab-inner-item{
          width: 15px;
          height: 15px;
          .icon-size{
            width: 15px;
          }
        }
        .sa-details {
          font-size: 0.5rem;
        }
      }
    }
    .button-sort-section{
      width: 100% !important;
      display: flex;
      flex-direction: column-reverse;
      margin-top: 5px !important;
      .burger-menu {
        padding: 14px 20px;
        img{
          width: 15px;
          margin: 0 0.2rem 
        }
      }
      .buttons-section {
        width: 100% !important;
        .sort-box-btn{
          width: fit-content !important;
          span{
            text-wrap: nowrap;
          }
        }
      }
      .sort-section{
        height: 45px;
        width: 100%;
        border-radius: 0;
        padding: 1.8rem 1rem !important;
        .search {
          width: 85%;
          height: 35px;
          padding: 5px 0px 5px 10px !important;
        }
        .sort-button-right {
          width: 35px;
          height: 35px;
          padding: 0;
          display: flex;
          justify-content: center;
          img{
            width: 20px;
          }
          span{
            display: none;
          }
        }
      }
    }
    .cards-section{
      padding: 0rem;
      width: 100%;
      gap: 0.5rem;
    }
    hr {
      margin: 0.2rem 0;
    }
    .card {
      grid-template-columns: 2fr 8fr;
      border-radius: 10px;
      .left-image{
        width: 110px;
        max-height:120px;
        border-radius: 10px;
        display: flex;
        margin-right: 5px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img{
          border-radius: 10px;
          height: -webkit-fill-available;
          width: 200px;
        }
      }
      .left-section{
        margin-left: 1px;
        margin-right: 1px;
        width: 95%;
        .left-top {
          padding-top: 2px;
          .details-btn-section{
            align-items: center;
            .details-button {
              border-radius: 50px;
              font-size: 0.7rem;
              display: flex;
              align-items: center;
              img{
                width: 12px;
                margin: 0;
              }
              span{
                font-size: 0.6rem;
                padding: 0;
              }
            }
            p{
              color: #FA8500;
              font-weight: bold;
              font-size: 0.6rem;
              margin: 0;
            }
          }        
          .auc-head{
            width: 100%;
            .data-h3{
              font-size: 0.6rem;
              margin: 0;
            }
            .lot {
              font-size: 0.5rem;
              margin: 0;
            }
          }
        }
        .left-bottom{
          display: flex !important;
          align-items: center;
          .cont-amount{
            font-size: 0.5rem;
            background: #efefef;
            display: flex;
            margin-bottom: 1px;
            border-radius: 50px;
            padding: 0px 6px;
          }
          .bid-now-btn{
            font-size: 0.6rem;
            padding: 10px 10px;
            height: 28px;
          }
          .lb {
            padding: 0px;
            gap: 0.1rem;
            span{
              text-wrap: pretty;
              font-size: 0.5rem;
            }
          }
        }
      }
    }
}
    .auc-card-container{
      width: 90%;
      .content-div {
        .outer-div{
          margin: auto !important;
          margin-top: 0.5rem !important;
        }
      }
    }
    .auctions-marketplace-div {
        width: 100% !important;
        display: flex;
        flex-direction: column !important;
    }
    .mzad-footer{
      .head-office{
          .head-office-map{
              img{
                  width: 100%
              }
          }
      }
    }
    #page-container-otp-login {
      .content {
        .main-container{
          overflow: hidden;
          .inner-container{
            background-color: white;
            display: block;
            padding: 0;
            .left-section {
              padding: 1rem;
              background: #FFF;
              width: 100%;
              .buttons{
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          } 
        } 
      }
    }

    .contact-wrapper{
      flex-direction: column-reverse;
    }
    .hot-auction-button{
      margin-bottom: 0px;
      .hot-p {
        margin-top: 18px;
        font-size: 15px;
      }
    }

    .mobile-sidebar-offcanvas {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 10px;
      background-color: #76acc1;
      border-radius: 10px;
      margin: 10px 0px;
      gap: 10px;
      .mobile-balance-btn-div {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin: 15px 30px;
        padding: 0px 30px;
        .mobile-balance-btn {
          background-color: #f7c193;
          border: none;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 10px;
          font-size: 12px;
        }
      }
      .mobile-menu-row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        flex-wrap: wrap;
        .mobile-menu-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #eaf2f561;
          border-radius: 10px;
          padding: 10px 10px;
          gap: 5px;
          flex-wrap: wrap;
          min-width: 75px;
          color: #000;
          font-size: 0.5rem;
          img {
            width: 15px;
          }
          &.active {
            background-color: #eaf2f5;
          }
        }
      }
    }

    .offcanvas-body{
      a {
        color: black;
        padding: 5px 25px;
        text-decoration: none;
        display: block;
      }
      .menu-category{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #ccc;
        justify-content: space-between;
      }
      .offcanvas-mid-section{
        border-bottom: none;
      }
      .offcanvas-auctions{
        border-bottom: none;
        display: none;
        transform: scale(0);
        transition: transform 0.3s ease;
        &.active{
          display: flex;
          transition: transform 0.3s ease;
          transform: scale(1);
        }
      }
      .offcanvas-auc-menu{
        display: none;
        transform: scale(0);
        transition: transform 0.3s ease;
        &.active{
          width: 100%;
          display: block;
          transition: transform 0.3s ease;
          transform: scale(1);
          border-bottom: 1px solid #ccc;
        }
        a{
          color: black;
          padding: 5px 25px;
          text-decoration: none;
          display: block;
        }
      }
    }

    #winning-bids-container{
      .container_grid{
        grid-template-columns: 1fr;
        form{
          &.card{
            padding: 1rem;
          }
        }
      }
      .scrollable-container{
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        &.stacked {
          flex-direction: column;
        }
        .scrollable-item {
          flex: 0 0 auto;
          width: 100%;
          max-width: auto;
          transition: transform 0.3s ease-in-out;
        }
      }
    } 
}

@media screen and (max-width: 768px) and (max-height: 242px) {
    #inner-div-double {
        width: 300px;
    }
    #inner-div-double2 {
        width: 300px;
    }
    .double-container {
        width: 300px;
        height: 210px;
    }
    .double-container-hot-auction {
        width: 300px;
        height: 210px;
    }
    .hot-p {
        margin-top: 10px;
    }
    .p-contents {
        font-weight: 500;
        text-align: left;
        margin: 5px 0;
        color: #000000;
    }
    #m {
        margin-left: 0px;
    }
    #auction-head {
        margin-left: 30px;

    }
    .partner-images {
        height: 50;
        width: 50;

    }
    .auction-container {
        display: none;

    }
    .auc-card-wrapper{
        .slick-slider{
            .slick-list{
                margin: auto;
                width: 100%;
            }
        }
    }
    .image-container {
        display: none;

    }
    .auc-card-wrapper{
        .slick-slider{
            .slick-list{
                margin: auto;
                width: 100%;
            }
        }
    }
    .footer-message{
      display: none !important;
    }
}

@media (max-width:768px) {
  .modal-60w{
    min-width: 90% !important;
  }
  .modal-50w{
      min-width: 90% !important;
  }
  .grid_mob_container{
    display: flex !important;
    flex-direction: column;

  }
  .payment-info-section{
    width: 100%;
    overflow: scroll;
    .payment-info-container {
      min-width: 200px;
    }
  }
  .column-direction{
    flex-direction: column;
  }
  .w-mob-100{
    width: 100% !important;
  }
  .mob-field{
    padding-right: 0rem !important;
    padding-left: 5rem !important;
  }
  .card {
    .card-body{
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
  .w-200{
    width: 45%;
  }
  .bidding-comp {
    .bidding-cmp-head {
      .head-enroll{
        font-size: 0.5rem;
      }
    }
  }
  .track-head{
    flex-direction: column;
  }
  .track-wrapper{
    .timeline-steps{
      justify-content: left;
    }
  }
  .auction-details-section{
    .custom_text2{
      &.is-size-12{
        font-size: 12px;
      }
    }
  }
  h5{
    &.fs-mob-8{
        font-size: 0.8rem;
    }
  }
  .w-70{
    width: 99%;
  }
  .w-80{
    width: 99%;
  }
  .double-container {
    width: 100%;
  }
  .double-container-hot-auction {
    width: 100%;
  }
  .search-box {
      margin-left: 0px !important;
  }
  .nav-menu {
      display: none !important;
  }
  .mobile-blue-nav-container {
      width: 100%;
      display: flex;
      justify-content: center;
  } 
  .auction-container-number {
      width: 30px; 
      height: 15px; 
      display: flex; 
      align-items: center;
      justify-content: center;
      background-color: #00B76E; 
      color: white;
      font-weight: normal;
      font-size: 10px;
      margin-right: 5px;
  }
  .mobile-blue-nav-container {
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .mzad-footer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .middle-nav {
      display:flex;
      justify-content: center;
      margin-top: 20px;
  }
  .auc-card-wrapper{
      .slick-slider{
          .slick-list{
              margin: auto;
              width: 100%;
          }
      }
  }
  .auction-details-section{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    .cus_felx{
      span{
        padding: 0.5rem !important;
      }
    }
    .custom_text{
      font-size: 10px;
      text-wrap: nowrap;
    }
    .custom_text2{
      display: flex;
      font-size: 16px;
    }
    .custom_text10{
      font-size: 1rem !important;
      padding: 1rem 0rem !important;
    }
    .documents{
      .flex-background{
        padding: 1rem !important;
      }
      .button_colour{
        display: flex;
        margin-top: 0 !important;
        img{
          width: 30px;
          height: 30px;
        }
        .custom_text12{
          display: none;
        }
      }
      i{
        font-size: 2rem !important;
      }
    }
  }

  .cloud-upload-btn-wrapper {
    padding: 0.5rem !important;
    .uploader-info-title{
        font-size: 0.6rem;
    } 
    .uploader-info-subtitle{
        font-size: 0.5rem;
    }
    .uploader-placeholder{
      font-size: 0.7rem;
    } 
    .uploader-format-viewer{
      position: absolute;
      z-index: 999;
      background: none;
      padding: 0.1rem 0.2rem;
      bottom: 0;
      right: 5px;
    }
  }
}

@media (max-width: 750px) {
    .header-main {
        display: none;
    }
    .header-tab-mobile {
        display: block;
    }
    .sub-menu {
        display: none !important;
    }
}

@media (max-width:576px) {
    .carousel {
        padding: 0 0px !important;
    }
    .carousel-inner {
        border-radius: 20px !important;
    }
    .support-button {
      display: none;
      span{
        display: none;
      }
    }
    .support-button img {
        width: 15px;
    }
    .mobile-search-filter {
        display: none;
    }
    .inner-div-double2 {
        display: none !important;
    }
}

//Login

@media (max-width: 768px) {
  .header-main {
    display: none;
  }
  .header-tab-mobile {
    display: block;
  }
}
@media (max-width: 500px) {
  .date-country-lang {
    font-size: 10px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 334px) {
  .auc-card-container{
    .content-div{
      .contaiiner-amt{
        .col-one{
          span {
            font-size: 0.5rem;
          }
        }
      }
    } 
  }   
}
  
  @media screen and (max-device-width: 768px) {
    .mobile_hide{
      display: none;
    }
    .mobile_size span{
      font-size: 15px !important;
    }
    .mobile_size img{
      
      height: 18px;
      width: 18px;
    }
  }

  @media screen and (min-device-width: 570px) and (max-device-width: 991px) {
    .custom_res {
      display: flex; /* Add this to make it a flex container */
      align-items: center;
      height: 29px;
      font-size: 12px;
    }
  }
  
  @media screen and (min-device-width: 410px) and (max-device-width: 536px) {
    .custom_res {
      display: flex; /* Add this to make it a flex container */
      align-items: center;
      height: 29px;
      width: 100%;
      font-size: 12px;
      overflow: hidden;
    }
  }
  @media screen and (min-device-width: 300px) and (max-device-width: 410px) {
    .custom_res {
       /* Add this to make it a flex container */
      align-items: center;
      height: 29px;
      width: 100%;
      font-size: 9.5px;
      overflow: hidden;
    }
    .custom-text3{
      font-size: 12px !important;
    }
    .registraion_type{
      font-size: 13px;
    }
  }


  @media screen and (min-device-width: 765px) and (max-device-width: 1000px) {
    .dashboard_res {
      font-size: 28px;
      
    }
  }
  @media screen and (max-device-width: 476px) {
    .support-button{
      display: none;
      span{
        display: none;
      }
    }
  }

  .left-menu-black a{
    font-size: 14px;
  }
  @media screen and (max-device-width: 991.5px){
    .custom-div_color{
      display: none;
    }
    .mobile_hide_div{
      display: none;
    }
    .hide_side_nav{
      display: none;
    }
    .form_div{
      align-items: center;
    }
    .cnter_div_mobile{
      display: flex;
      justify-content: center;
    }
    .dashboard_res {
      font-size: 20px;
      
    }
    .Dashboard_mob_size{
      height: 23px;
      width: 25px;
    }
    .offcanvas-header{
      align-items: normal;
    }
    .left-menu-black{
      font-size: 12px;
    }
    .left-menu-black a{
      font-size: 12px;
    }
    .left-menu-white-brd{
      border-left: 30px solid #ffffff !important;
      font-size: 12px;
    }
    .left-menu-white-brd a{
      border-left: 30px solid #ffffff !important;
      font-size: 12px;
    }

      
   

  }
  @media screen and (min-device-width: 991px){
    .fs24{
      font-size: 22px;
    }
      
    
  }

  @media screen and (min-device-width: 350px) and (max-device-width: 390px) {
    .canvas_width{
      width: 70% !important;
    }
  }
  @media screen and (min-device-width: 390px) and (max-device-width: 446px) {
    .canvas_width{
      width: 60% !important;
    }
  }
  @media screen and (min-device-width: 550px) and (max-device-width: 660px) {
    .canvas_width{
      width: 45% !important;
    }
  }
  @media screen and (min-device-width: 660px) and (max-device-width: 991px) {
    .canvas_width{
      width: 35% !important;
    }
  }