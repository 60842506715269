//@import "./styles//fontawesome.scss";
@import "./styles/main.scss";
@import "./styles/landng.scss";
@import "./styles/pages.scss";
@import "./styles/footer.scss";
@import "./styles/arabic.scss";
@import "./styles/mobile.scss";
@import "./styles/safari.scss";
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; 
@import './styles/accordion.scss';
@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';