.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    text-align: left;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #e7f1ff;
    transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #e7f1ff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}