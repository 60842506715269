@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    background: #f7f7f7;
    //background-image: url('../../public/assets/images/content-bg.jpg');
    //background-size: 100%;
    font-family: 'Roboto', sans-serif;
    &.ar{
        direction: rtl;
    }
}
.btn-expand{
    border-radius: 20px !important;
    width: 20px;
    height: 20px;
    padding: 0.1rem 0.2rem !important;
    margin: 0;
    border: 0;
    background: #FF0000;
    color: #FFF;
    box-shadow: 2px 2px 1px 1px #ccc;
}
.modal-content{
    border-radius: 2rem;
}
.modal-header{
    justify-content: center;
    .btn-close{
        width: 15px !important;
        height: 15px !important;
        background: url("../../public/assets/images/close.png");
        background-size: contain;
    }
}
.table-cust{
    width: 100%;
    tr:nth-child(even) {
        background-color: #FFF;
    }
    tr{
        border-width: 1px;
        td {
            padding: 10px;
            border: 1px solid #ccc;
            border-bottom: 0;
        }
    }
}
form{
    width: 100%;
}
.w-25{
    width: 25%;
}
.w-30{
    width: 30%;
}
.w-33{
    width: 33%;
}
.w-50{
    width: 50%;
}
.w-100{
    width: 100%;
}
.mob-field{
    padding-right: 6rem ;
    padding-left: 6rem ;
}
.w-200{
    width: 200px;
}
.border-25{
    border-radius: 25px !important;
}
.btn-n1{
    font-size: 0.5rem !important;
    padding: 1px 5px !important;
    border: none;
    color: #FFF;
    background: #ff0000;
    text-decoration: none;
    &:hover{
        background: #FFF;
        color: #ff0000;
        border: 0.5px solid #ff0000;
    }
}
.form-group{
    margin-bottom: 1.5rem;
    .form-label{
        font-size: 0.9rem;
        margin-left: 0.9rem;
        margin-right: 0.9rem;
    }
    .form-control{
        border-radius: 50px;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
    textarea{
        border-radius: 10px !important;
    }
}
h5{
    &.fs-mob-8{
        font-size: 1.25rem;
    }
}
.btn{
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    height: fit-content;
    text-wrap: nowrap;
}
.upload-btn{
    border-radius: 50px;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    background-color: #F7C193; 
    border: 1px solid #fba65c;;
}
.sc-highlight{
    background: #c0e8f4;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    padding: 1rem;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}
.clock-div{
    font-size: 0.8rem;
    padding-bottom: 0px !important;
    margin-bottom: -5px !important;
    .clock-item{
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        .border-span{
            font-weight: 100;
            background: #02b76d;
            border-radius: 20px;
            font-size: 0.5rem;
            color: #FFE;
            padding: 0.1rem 0.2rem;
            bottom: -0.4rem;
            &.days{}
            &.hours{}
            &.minutes{}
            &.seconds{}
        }
    }
}
.track-wrapper{
    .track-item{
        background-color: #e5f9ff;
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        h5{
            font-size: 1rem;
            font-weight: bold;
        }
    }

    .timeline-steps {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        .item-head{
            font-weight: bold;
            font-size: 0.9rem;
        }
        .item-date{
            background: #a4d4e2;
            font-size: 0.6rem;
            padding: 0.2rem 0.1rem;
            border-radius: 5px;
            font-weight: bold;
        }
    }
    
    .timeline-steps .timeline-step {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0.1rem;
    }
    
    // @media (min-width:768px) {
        .timeline-steps .timeline-step:not(:last-child):after {
            content: "";
            display: block;
            border-top: .2rem solid #cdcdcd;
            width: 3.46rem;
            position: absolute;
            left: 7.5rem;
            top: .3125rem;
        }
        .timeline-steps .timeline-step:not(:first-child):before {
            content: "";
            display: block;
            border-top: .2rem solid #cdcdcd;
            width: 3.8125rem;
            position: absolute;
            right: 6.1rem;
            top: .3125rem;
        }
    //}
    
    .timeline-steps .timeline-content {
        width: 7rem;
        text-align: center;
    }
    
    .timeline-steps {
        .timeline-content {
            .inner-circle {
                border-radius: 1.5rem;
                height: 1rem;
                width: 1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: #ef0000; 
                &.active{
                    background-color: #009025;
                }
            }
        }
    }
    
    .timeline-steps {
        .timeline-content {
            .inner-circle {
                &:before {
                    content: "";
                    background-color: #ef0000;
                    display: inline-block;
                    height: 1.5rem;
                    width: 1.5rem;
                    min-width: 1.5rem;
                    border-radius: 6.25rem;
                    opacity: .5;
                }
                &.active{
                    &:before {
                        background-color: #009025; 
                    }
                }
            }
        }
    }
}
.carousel-root{
    direction: ltr !important;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.font-weight-bold{
    font-weight: bold !important;
}
.bg-card{
    background: #23406f !important;
    color: #FFF !important;
}
.fs-5rem-mob{
    font-size: 1rem !important;
}
.fs-7rem-mob{
    font-size: 1rem !important;
}
.fs-5rem{
    font-size: 0.5rem !important;
}
.fs-9{
    font-size: 0.9rem !important;
}
.mdi--login {
    display: inline-block;
    width: 1em;
    height: 1em;
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8z'/%3E%3C/svg%3E");
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
.btn{
    &:disabled{
        color: #757575;
        pointer-events: none;
        background-color: #bebebe;
        border-color: #919191;
    }
}
.btn-primary{
    background: #000;
    border: 1px solid #7c7c7c;
}
.btn-warning{
    background: #fa8500 !important;
}
.btn-outline-warning{
    color: #fa8500 !important;
    border-color: #fa8500 !important;
}
.text-justify{
    text-align: justify !important;
}
.col-xl-12 {
    font-size: 1rem;
}
.w-desk-75{
    width: 75% !important;
}
.modal-90w{
    min-width: 90% !important;
}
.modal-60w{
    min-width: 60% !important;
}
.modal-50w{
    min-width: 50% !important;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-outline-danger{
    &:hover{
        background-color: #FFA5A5;
        i{
            color: #FFF;
        }
        span{
            color: #FFF;
        }
    }
}
.custom-text3 {
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: justify;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.show_mobile{
    display: none !important;
}
.mt-7 {
    margin-top: 7rem!important;
}
.px-6{
    padding-right: 4rem!important;
    padding-left: 4rem!important;
}
.px-7{
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

footer a {color: #fff; text-decoration: none;}

.golden-embosed{
    background: linear-gradient(45deg, #ffd700, #ffd700 50%, #cc9900 50%, #cc9900);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #333; 
    padding: 10px;
}
/* Header */
header {
    background: #F6F6F6;
}
.card{
    border-radius:1rem;
    border: 1px solid #dee2e6 !important;
    .card-title{
        font-size: 1.3rem;
    }
    .card-header{
        border: none;
    }
    .card-body{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}
.card-header{
    background: #FFFFFF;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.logo-bg {
    box-shadow: 0px 2px 28px 0px #00000040;
    width: 136px;
    margin: 0 auto;
}

.head-menu-black a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
}

.blue-menu-bg {
    background: #183642;
    font-size: 17px;
}

.blue-menu-bg a {
    color: #fff;
    text-decoration: none;
}

.blue-menu-bg .active {
    border-radius: 50px;
    border: solid 2px #FA8500;
    font-weight: 700;
}

.content-bg {
    background: url('../../public/assets/images/content-bg.jpg') no-repeat top center;
    background-size: cover;
}

.dashboard-bg {
    background: #76ACC1;
}
.left-menu-black a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

.left-menu-black .active {
    background: #EAF2F5;
    border-left: 10px solid #76ACC1;
}

.left-menu-white-brd {
    border-left: 10px solid #ffffff;
}
/* Comman */
/* text size */
.fs6 {
    font-size: 6px;
}
.fs8 {
    font-size: 8px;
}
.fs10 {
    font-size: 10px;
}
.fs12 {
    font-size: 12px;
}
.fs13 {
    font-size: 13px;
}
.fs14 {
    font-size: 14px;
}
.fs15 {
    font-size: 15px;
}
.fs16 {
    font-size: 16px;
}
.fs18 {
    font-size: 18px;
}
.fs20 {
    font-size: 20px;
}
.fs22 {
    font-size: 22px;
}
.fs24 {
    font-size: 24px;
}
.fs25 {
    font-size: 25px;
}
.fs30 {
    font-size: 30px;
}
.fs32 {
    font-size: 32px;
}
.fs40 {
    font-size: 40px;
}

/* Text Color */
.text-gray {
    color: #7f7f7f
}
.text-green {
    color: #2C9731;
}
.text-blue {
    color: #46BCFF;
}

/* Background */
.bg-none{
    background:none !important;
}
.bg-active {
    background: #A9E7FF;
}

.bg-upcoming {
    background: #7AFFAF;
}

.bg-expired {
    background: #FFD1A9;
}

.bg-featured {
    background: #FF9790;
}

.bg-lt-blue {
    background: #E6F8FF;
}

.bg-green {
    background: #13C650;
}
.bg-orange {
    background: #FFA640;
}
.bg-purple {
    background: #9262DF;
}
.bg-red {
    background: #FF514E;
}
.bg-blue {
    background: #477BFF;
}
.bg-yellow {
    background: #FFCF23;
}
.bg-pink {
    background: #FA00B4;
}
.bg-footer {
    background: #254F5F;
}

.bg-copyright {
    background: #183642;
}

.bg-lt-blue {
    background: #C9E2E9;
}

.bg-lt-green {
    background: #21D78E;
}

.bg-lt-orange {
    background: #F7C193;
}
.bg-lt-red {
    background: #FF726F;
}
.bg-lt-green1 {
    background: #00B76E;
}

.bg-table-head {
    --bs-table-bg: #76ACC1;
    --bs-table-color: #fff;
    --bs-border-radius: 30px;
}
.wrap-pretty{
    text-wrap: pretty;
}
.wrap-no{
    text-wrap: nowrap;
}
.wrap-balance{
    text-wrap: balance;
}


table {
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
}

th {
    background-color: #e0413d;
    color: #FFFFFF;
}

th, td {
    padding: 10px;
    border: none;
}

tr:nth-child(even) {
    background-color: #d0d0d0;
}
/* required css to make rounded table (below) */
thead:first-child th:first-child {
    border-top-left-radius: 20px;
}

tr:first-child th:last-child {
    border-top-right-radius: 20px;
}

tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

/* font style */
.fwn {
    font-weight: 400;
}
.fwb {font-weight: 700;}
.lh20 {
    line-height: 20px;
}
.lh28 {line-height: 28px;}
.lh32 {
    line-height: 32px;
}
.mt-minus-3 {
    margin-top: -1rem !important;
}
/* Borders */
.user-brd {
    border: 1px solid #D2D2D2;
}
.table-body-brd {
    border-left: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
}

footer hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ffffff;
    padding: 0;
}

.fa {
    &.fa-angle-up{
        font-size: 1.3rem;
    }
    &.fa-angle-down{
        font-size: 1.3rem;
    }
}
@keyframes rotateThis {
    from{ 
        transform: rotate( 0deg );  
        -webkit-transform: rotate( 0deg );  
    }
    to{ 
        transform: rotate( 360deg ); ;
        -webkit-transform: rotate( 360deg ); 
    }
}
.glyphicon{
    font-size: 0.8rem;
    &.glyphicon-refresh-animate {
        animation-name: rotateThis;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        -webkit-animation-name: rotateThis;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
     }
}
.mdi{
    font-size: 1rem;
    &.mdi-refresh-animate {
        animation-name: rotateThis;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        -webkit-animation-name: rotateThis;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
    }
}

/* Input */
.textbox {
    background-color: #EAF2F5;
    border: 1px solid #76ACC1;
    border-radius: 10px;
}

.select {
    background-color: #EAF2F5;
    border: #EAF2F5;
    border-radius: 10px;
}
.input{
    background-color: #EAF2F5;
    border: #EAF2F5;
    border-radius: 10px;
}

// .filter-icon {
//     //background: url('../../public/images/filter-icon.png') #EAF2F5 no-repeat right 5px;
// }

/* Buttons */
.btn-file{
    background-color: #dee0e3;
}
.btn-outline-black {
    border-color: #000000;
    font-size: 14px;
}

.btn-outline-black:hover {
    border-color: #000000;
}

.btn-red {
    background: #EF2D28;
    border-radius: 50px;
    font-size: 15px;
    color: #fff;
}

.btn-red:hover {
    background: #EF2D28;
    color: #fff;
}

.btn-blue {
    background: #00A9E4;
    border-radius: 50px;
    font-size: 15px;
    color: #fff;
}

.btn-blue:hover {
    background: #00A9E4;
    color: #fff;
}

.btn-floating{
    width: 35px;
    height: 35px;
    line-height: 1.7;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.1);
    i {
        display: inline-block;
        width: inherit;
        text-align: center;
        color: #fff;
        line-height: 36px;
    }
}
.flex-background {
    background-color: #F2F2F2;
}
.divider {
    height: 20px;
    background-color: #1a1e42;
    width: 100%;
    margin-bottom: 40px;
  }
.flag-dd{
    display: block !important;
    position: absolute !important;
    .dropdown-menu{
        &.show {
            display: block;
            height: 150px;
            overflow: scroll;
        }
    }
    .btn-country{
        margin: 0 !important;
        border: none;
        &::after {
            display: inline-block;
            margin-left: 2em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.2em solid transparent;
            border-bottom: 0;
            border-left: 0.2em solid transparent;
            position: absolute;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 1.3rem;
        }
        img{
            width: 35px !important;
            border-radius: 11px 0px 0px 11px;
        }
    }
}


.load-more{
    margin-top: 1rem;
    line-height: 1rem;
    a{
        color: #000;
    }
}

.recaptcha {
    margin: 0 auto;
    width: 65%;
    margin-bottom: 1rem;
    transform-origin:inherit !important;
    display: grid;
    align-content: center;
    justify-content: center;
}
.dashboard-wrapper{
    .form-group{
        padding: 0.5rem;
    }
}
.item1-links{
    a{
        color: #000;
        text-decoration: none;
        font-size: 16px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &.active{
            background-color: #eaf2f5;
            border-left: 10px solid #76acc1;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
.table{
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    thead{
        th{
            background-color: #e4f7ff;
            color: #000000;
        }
    }
    tbody{
        tr{
            td{
                color: #000;
                img{
                    width: 50px;
                }
            }
        }
    }
}
.table-wrapper{
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    margin-top: 1rem;
    .btn{
        border-radius: 50px;
        padding: 0.3rem 1.5rem;
        font-weight: 400;
        &.btn-sm{
            padding: 0.2rem 0.8rem;
        }
    }
    a{
        color: #d90400;
        text-decoration: none;
        font-weight: bold;
        &.btn{
            border-radius: 50px;
            padding: 0.3rem 1.5rem;
            font-weight: 400;
            font-size: 0.6rem;
            color: #FFF;
            &.btn-sm{
                padding: 0.2rem 0.8rem;
            }
        }
    }
    .RvYtY{
        border-radius: 20px !important;
    }
}

.payment-info-section {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .payment-info-container {
        padding: 10px 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
    }
}

.bidding-comp{
    .toggle{
        background: #ffc8c8;
        border-radius: 50px;
        font-size: 0.8rem;
    }
    .bidding-comp-fields{
        .btn {
            position: relative;
            z-index: 2;
            font-size: 0.7rem;
            height: 30px;
            width: 30px;
            padding: 0.1rem;
        }
    }
    .btn.active {
        background-color: #FFA5A5 !important;
    }
    .bidding-cmp-head{
        font-size: 0.9rem; 
        display: flex; 
        width: 100%; 
        justify-content: space-between;
        .head-enroll{
            font-size: 0.7rem;
            color: #FF0000;
            margin-top: 0px;
            border: 1px solid #FF0000;
            padding: 0.3rem;
            border-radius: 50px;
            background: #fff0f0;
        }
    }
}

.upload-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    label{
        text-wrap: nowrap;
        overflow: hidden;
        font-size: 0.8rem;
        &::after{
            content: "...";
        }
    }
    .btn{
        font-size: 0.8rem;
        width: 165px 
    }
    input[type=file] {
        font-size: 100px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        height: -webkit-fill-available;
    }
}

.cloud-upload-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 2rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border: 1px dashed #d2d2d2 !important;
    &.is-empty{
        border: 1px dashed #ff0000 !important; 
    }
    .uploader-icon{
        img{
            width: 3rem;
            height: auto;
        }
    } 
    .uploader-info-title{
        text-wrap: nowrap;
        overflow: hidden;
        font-size: 0.8rem;
    } 
    .uploader-info-subtitle{
        font-size: 0.7rem;
    }
    .uploader-placeholder{
        text-wrap: nowrap;
        font-size: 0.8rem;
        width: 165px; 
    } 
    .uploader-format-viewer{
        z-index: 9999;
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    input[type=file] {
        font-size: 100px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        height: -webkit-fill-available;
    }
}

.grid_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    .flex_container {
        display: flex;
        align-items: center;
        gap: 10px;
        &.outer_round1 {
            background-color: #A9E7FF;
            border-radius: 10px;
            width: 100%;
            padding: 1vh;
        }
        &.outer_round2 {
            background-color: #7AFFAF !important;
            border-radius: 10px;
            width: 100%;
            padding: 1vh;
        }
        &.outer_round3 {
            background-color: #F7C193;
            border-radius: 10px;
            width: 100%;
            padding: 1vh;
        }
        &.outer_round4 {
            background-color: #FFA5A5;
            border-radius: 10px;
            width: 100%;
            padding: 1vh;
        }
        .inner_round {
            border-radius: 10px;
            background-color: white;
            padding: 15px;
            span {
                display: flex;
            }
        }
        .outer_text {
            color: #000;
            font-size: 18px;
            font-weight: 400;
            span {
                display: flex;
                color: #000;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}
.btn-toggle-custom{
    .btn{
        &:hover{
            background: #0dcaf0 !important;
            color: #000 !important;
            border-radius: 1rem;
        }
    }
}
.grid_container_below {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-column-gap: 20px;
    column-gap: 20px;
    row-gap: 20px;
    .outer_circle {
        border-radius: 4rem;
        width: 100%;
        height: 90%;
        padding: 1vh;
        &.color-light-blue {
            background-color: #E6F8FF;
        }
        &.flex_container {
            display: flex;
            align-items: center;
            gap: 10px;
            span{
                display: flex;
            }
        }
        .inner_circle {
            border-radius: 50%;
            padding: 13px;
        }
        .color-green {
            background-color: #13C650;
        }
        .color-orange {
            background-color: #FFA640;
        }
        .color-purple {
            background-color: #9262DF;
        }
        .color-red {
            background-color: #FF514E;
        }
        .color-blue {
            background-color: #477BFF;
        }
        .color-yellow {
            background-color: #FFCF23;
        }
        .color-pink {
            background-color: #FA00B4;
        }
    }
}

.enroll{
    .custom-text3 {
        color: black !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
    }
}

.slick-slider{
    .slick-arrow{
        background: none;
        z-index: 999;
        overflow: hidden;
        width: 35px;
        height: 35px;
        border: 1px solid #ccc;
        padding: 0rem 0rem;
        border-radius: 50px;
        background: #FFF;
        i{
            color: #646464;
            font-size: 1.8rem;
        }
        &.slick-prev{
            &:before{
                content: '';
            }
            left: 2rem;
        }
        &.slick-next {
            &:before{
                content: '';
            }
            right: 2rem;
        }
    }
}
.nav-tabs{
    border: 1px solid #ccc;
    margin: 0.5rem;
    padding: 0.8rem 0.5rem;
    display: flex;
    gap: 0.2rem;
    background: #fbfbfb;
    border-radius: 10px;
    li{
        a{
            color: #76acc1 !important;
            border: 1px solid #76acc1 !important;
            padding: 0.5rem;
            border-radius: 5px;
        }
        &.active{
            a{
                color: #000 !important;
                background:  #76acc1 !important;
            }
        }
    }
}
.wallet-dash{
    .section-header {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-div {
            display: flex;
            align-items: center;
            gap: 10px;
            span{
                font-size: 25px;
                font-weight: 800;
            }
        }
        .header-right {
            display: flex;
            align-items: center;
            gap: 10px;
            .balance-btn {
                background-color: #fa8500;
                color: white;
                border-radius: 10px;
                border: none;
                padding: 10px 15px;
                font-size: 18px;
            }
            .omr-btn {
                border: 1px solid black;
                border-radius: 5px;
                padding: 5px 15px;
                background-color: white;
                font-size: 14px;
            }
        }
    }
    .dropdown-section {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
}
#winning-bids-container{
    .container_grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .right-column {
        padding: 10px;
        text-align: center;
        display: flex;
        gap: 10px;
    }
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid #dee3e7;
    border-radius: 20px;
    background: white;
}
.react-date-picker__calendar{
    z-index: 9999;
}
.calendar-wrapper{
    position: relative;
    z-index: 99999 !important;
}
@media screen and (max-width: 480px) {
    .recaptcha {
        width: 70%;
    }
}

@media screen and (max-width: 425px) {
    .recaptcha {
        width: 80%;
        transform: scale(0.85) !important;
        transform-origin:inherit !important;
    }
}

@media screen and (max-width: 375px) {
    .recaptcha {
        width: 93%;
    }
}

@media screen and (max-width: 375px) {
    .recaptcha {
        width: 100%;
        float: left;
        transform: scale(0.85) !important;
        transform-origin:inherit !important;
    }
}