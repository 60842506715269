body{
    &.safari{
        #auction-grouped {
            .card{
                .left-image{
                    max-height:120px;
                }
                .left-section{
                    height: auto;
                }
            }
        } 
    }
}